

.container {
  width: 100%;
  max-width: 800px;
  padding: 0 10px;
  margin: 0 auto;
  margin-top: 70px;
}

.title {
  text-align: center;
  margin-bottom: 30px;
}

table {
  border-collapse: collapse;
  width: 100%;
}
.table>th{
  font-weight:500;
}
tr th {

  padding: 12px;
  border: 1px solid #eeeeee;
  text-align: left;
  color: #ffffff;
  background-color:#4F5467;
}

tr td {
  border: 1px solid #eeeeee;
  text-align: left;
}

input {
  font-size: 16px;
  background-color: transparent;
  border: none;
  width: 91%;
  padding: 12px 12px;
  font-family: 'Poppins', sans-serif;
}

input:hover {
  background-color: rgb(204, 204, 204)60;
}

input:focus {
  outline: 1px solid #ccc;
  border: 1px solid #ccc;
}


.file-upload {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.file-upload.dragging {
  background-color: #e0e0e0;
}

.file-upload .upload-icon {
  font-size: 40px;
  margin-bottom: 10px;
}

.file-upload p {
  margin: 0;
  font-size: 16px;
  color: #666;
}






















.invoice {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}
.invoice-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.invoice-details > div {
  margin-right: 20px;
}
.invoice-body table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.invoice-body table th,
.invoice-body table td {
  border: 1px solid #ddd;
  padding: 8px;
}
.invoice-summary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.invoice-footer {
  text-align: center;
}
